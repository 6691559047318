/* fonts imports */

@font-face {
    font-family: "Montserrat-Regular";
    src: local("Roboto-Regular"), url('./assets/fonts/Roboto/Roboto-Regular.ttf') format("truetype");
 }
 
 @font-face {
    font-family: "Montserrat-Bold";
    src: local("Roboto-Bold"), url('./assets/fonts/Roboto/Roboto-Bold.ttf') format("truetype");
 }
 
 @font-face { 
    font-family: "Roboto-Regular";
    src: local("Roboto-Regular"), url('./assets/fonts/Roboto/Roboto-Regular.ttf') format("truetype");
 }
 
 @font-face {
    font-family: "Roboto-Bold";
    src: local("Roboto-Bold"), url('./assets/fonts/Roboto/Roboto-Bold.ttf') format("truetype");
 }

 /* font classes */

.font-mr{
   font-family: 'Montserrat-Regular';
}

.font-mb{
   font-family: 'Montserrat-Bold';
}

.font-rr{
   font-family: 'Roboto-Regular';
}

.font-rb{
   font-family: 'Roboto-Bold';
}

.mt-6{
   margin-top: 4rem;
}

.mt-7{
   margin-top: 4.5rem;
}

.mt-8{
   margin-top: 5rem;
}

.mt-9{
   margin-top: 5.5rem;
}

.mt-10{
   margin-top: 6rem;
}




.w-100{
   width: 100%;
}

.w-98{
   width: 98%;
}
.w-90{
   width: 90%;
}
.w-83{
   width: 83%;
}
.w-80{
   width: 80%;   
}

.w-70{
   width: 70%;   
}

.w-60{
   width: 60%;
}

*{
   font-family: "Roboto-Regular" !important;
}


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
